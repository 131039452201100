'use client';

import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';

import { useTranslation } from 'i18n/client';
import { config } from 'core';

const Error = ({ error, params }: { error: Error & { digest?: string }; params: { locale: string } }) => {
    const { t } = useTranslation(params?.locale || 'ba', 'error');

    useEffect(() => {
        console.error('%c (Dev/Local Environment): Some shit happened and this is the error:\n', 'background: #000; color: #FFF; font-size: 16px; font-weight: 700', error);

        if (config.SENTRY) {
            Sentry.captureException(error);
        }
    }, [error]);

    return (
        <div className="container m-[auto] pt-32">
            <h2>{t('TITLE')}</h2>
        </div>
    );
};

export default Error;
